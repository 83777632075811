<template>
  <div class="container">
    <div class="w690 por">
      <!-- 活动内容 -->
      <div class="activityContent">
        <div v-if="title" class="tit"> {{ title }}</div>
        <div class="content htmlWrap" v-html="setContent(info.content)"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import Vue from 'vue'
// import { formatDate } from "../utils/util"

export default {
  name: "commonPage",
  data() {
    return {
      title: '',
      info: {},
      type: this.$route.query.type,
    }
  },
  created() {
    this.getInfo()
    this.title = this.type == 1 ? "用户协议" : (this.type == 2 ? "隐私政策" : "");
  },
  methods: {
    getInfo() {
      this.$get('getAgreement', {
        'type': this.type
      }).then(res => {
        if (res.code == 200) {
          this.info = res.data;
        }
      }).catch(() => {
        // console.log(res)
      })
    },
    setContent(res){
      const regex = new RegExp('<img', 'gi')
      return res.replace(regex, `<img style="max-width: 100%; height: auto;display:block;"`)
    }
  }
}
</script>
<style>
body,div,img,p,h1,h2{margin:0;padding: 0}
.w690 {
  /*width: 92vw;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  width: 100%;
  padding: 0;
  box-sizing: border-box
}

.activityContent {
  height: auto;
  background: #fff;
  padding-bottom: 0px;
}

.activityContent .tit {
  padding-top: 30px;
  font-size: 30px;
  line-height: 1.7;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  font-size: 26px;
  color: #666;
  margin-top: 0px;
}
</style>
